/*
 * @Author: chengyuzhang
 * @Date: 2021-01-25 18:37:46
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-09-12 14:29:19
 */
import Vue from 'vue';
import { isEasyCashNative, isAndroid } from 'ssr-common/util/webview/ua';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import BrandCountryMap from 'ssr-common/vue/constant/brand-country';
import Event from 'easycash/common/resource/event';
export default {
    uploadEvent: async (info = {}, route, BRAND, useAppMethod) => {
        const { elementType = 'Button', elementContent = '', tag = '', businessEvent = '', eventValue = '', eventType = 'C' } = info;
        const { name, path, fullPath } = route;
        const elementId = tag ? `${name}${tag}` : name;
        const params = {
            events: [{
                    eventType,
                    businessEvent,
                    eventValue,
                    componentId: `${elementId}:${BRAND}${path}`,
                    eventParams: {
                        activity: `${BRAND}:${fullPath}`,
                        elementType,
                        elementId,
                        elementContent
                    },
                    eventTime: Date.now(),
                    platformType: 'WEB'
                }]
        };
        const ua = window?.navigator?.userAgent;
        if (useAppMethod && isEasyCashNative(ua) && isAndroid(ua)) {
            try {
                WebviewInterface.callHandler('uploadEvent', JSON.stringify(params.events[0]));
                return;
            }
            catch (error) {
                // ignore
            }
        }
        await Event.uploadEvents(params, {
            hideLoading: true,
            hideToast: true,
            headers: {
                'YQG-PLATFORM-ENVIRONMENT-INFO': 'WEB',
                'Country': BrandCountryMap[BRAND]
            }
        }).catch(error => error);
        // 实时日志
        const { realTimeLog } = Vue.prototype.$app ?? {};
        if (realTimeLog && businessEvent) {
            try {
                const dataConvert = typeof elementContent === 'string' ? JSON.parse(elementContent) : elementContent;
                realTimeLog(businessEvent, dataConvert);
            }
            catch (error) {
                // ignore
            }
        }
        // 如果从shared里导入sensors，sensors会在服务端执行，里面有访问window的操作，会报错
        const sensors = Vue.prototype.$sensors;
        if (sensors && businessEvent) {
            try {
                const dataConvert = typeof elementContent === 'string' ? JSON.parse(elementContent) : elementContent;
                // 如果未进行初始化，也不会进行报错，神策内部实现
                sensors.track(businessEvent, { elementContent: dataConvert });
            }
            catch (error) {
                // ignore
            }
        }
    }
};
