/*
 * @Author: zhaoyang
 * @Date: 2024-05-11 16:01:12
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-08-26 16:42:17
 */

const routes = [
    // 积分活动 start
    {
        path: 'integral',
        name: 'Integral',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/anew-h5/integral')
    },
    {
        path: 'integral-record',
        name: 'IntegralRecord',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/anew-h5/integral/record')
    },
    {
        path: 'integral-detail',
        name: 'IntegralDetail',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/anew-h5/integral/detail')
    },
    {
        path: 'integral-rule',
        name: 'IntegralRule',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/anew-h5/integral/rule')
    },
    // 积分活动 end
    {
        path: 'social-media-outside',
        name: 'SocialMediaOutside',
        component: () => import('easycash/app/webview/social-media/outside'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: 'social-media-special',
        name: 'SocialMediaSpecial',
        component: () => import('easycash/app/webview/social-media/special'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: 'n-select-one',
        name: 'NSelectOne',
        component: () => import('easycash/app/webview/anew-h5/n-select-one'),
        meta: {
            business: {
                withUserStatus: true
            }
        }
    },
    {
        path: 'n-select-one-outside',
        name: 'NSelectOneOutside',
        component: () => import('easycash/app/webview/anew-h5/n-select-one-outside'),
        meta: {
            business: {
                withUserStatus: true
            }
        }
    },
    // 砸金蛋start
    {
        path: 'golden-egg',
        name: ' GoldenEgg',
        component: () => import('easycash/app/webview/anew-h5/golden-egg')
    },
    {
        path: 'golden-rule',
        name: 'GoldenRule',
        component: () => import('easycash/app/webview/anew-h5/golden-egg/rule'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true,
                withUserStatus: true
            }
        }
    }
    // 砸金蛋end
];

export default routes;
