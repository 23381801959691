/*
 * @Author: zhaoyang
 * @Date: 2024-08-22 17:15:55
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-08-22 17:22:01
 */
import { setTag } from '@sentry/vue';
import ua from './webview/ua-parsed';
import WebviewInterface from './webview/yqg-webview-interface';
export const setSentryCache = () => {
    try {
        if (!ua.isWebview)
            return;
        WebviewInterface.callHandler('isAppResourceCache', '', (isAppCache) => {
            setTag('isAppResourceCache', isAppCache || false);
        });
    }
    catch (error) {
        // ignore
    }
};
