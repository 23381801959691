/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-12-15 17:16:4749
 */

/* global __GTAG_ID__ */

import '@shared/client/util/public-path';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

import {rejectFailedStatus} from '@yqg/http';

import 'ssr-common/util/report';

import {initSensors} from '@shared/client/util/sensors';
import {initSentry} from '@shared/client/util/sentry';
import {getUserId} from '@shared/client/util/user-token';

import {isProd, isServer} from 'ssr-common/constant/config';
import {setSentryCache} from 'ssr-common/util/sentry-cache';
import {setSentryUser} from 'ssr-common/util/sentry-user';
import {isEasyCashNative, isEasyCashOppo, minVersion} from 'ssr-common/util/webview/ua';
import {createClientEntry} from 'ssr-common/vue/ssr/entry-client';
import http from 'ssr-common/vue/vue-http';

import EventUtil from 'easycash/common/util/event';
import {getKey} from 'easycash/common/util/gen-key';
import {
    getBrowserInfo,
    getUa
} from 'easycash/common/util/get-browser-info';

import {rootTitle, noStageTitle, BRAND} from './common/constant/config';
import {createApp} from './main';

http.interceptors.response.use(rejectFailedStatus());

const {router, store} = createClientEntry({createApp, rootTitle, noStageTitle});

Vue.use(VueGtag, {
    config: {id: __GTAG_ID__},
    includes: isProd() ? [
        {id: 'AW-830226380'}
    ] : []
}, router);

// sensors start
const isInApp = isEasyCashNative(navigator.userAgent);
const isOppo = isEasyCashOppo(navigator.userAgent);
if (minVersion(35913) || (isOppo && minVersion(35911)) || !isInApp) {
    const platformName = `easycash_${isInApp ? 'app' : 'outside'}`;

    Vue.prototype.$sensors = initSensors({
        platformName,
        country: 'indo',
        businessName: 'IDN_YQD',
        userId: getUserId(),
        useAppJsBridge: isInApp
    });
}
// sensors end

router.afterEach(async to => {
    const {query, meta = {}, name} = to;
    const {business = {}} = meta;
    const {
        enterEvent,
        uploadQuery,
        buriedPoint,
        withUserKey,
        withUserStatus,
        withBrowserInfo
    } = business;

    if (enterEvent && !isServer() && !buriedPoint) {
        const {elementContent = {}} = enterEvent;
        const event = {
            ...enterEvent,
            elementContent: uploadQuery ? JSON.stringify({
                ...query, ...elementContent
            }) : JSON.stringify({...elementContent})
        };
        EventUtil.uploadEvent(event, to, BRAND);
    }

    if (buriedPoint && !isServer()) {
        const browserInfo = getBrowserInfo(getUa());

        let finalQuery = withUserKey ? {...query, userKey: getKey()} : query;

        if (withUserStatus) {
            try {
                await store.dispatch('userStatus/getUserStatus');
            } catch (err) {
                // ignore
            }

            const status = store.state?.userStatus?.status;
            finalQuery = {...finalQuery, ...status};
        }

        if (withBrowserInfo) {
            finalQuery = {...finalQuery, ...browserInfo};
        }

        const event = {
            tag: 'PageEnter',
            businessEvent: `Easycash_${name}_PageEnter`,
            elementType: 'Button',
            eventType: 'P',
            elementContent: uploadQuery ? finalQuery : {}
        };
        EventUtil.uploadEvent(event, to, BRAND);
    }
});

initSentry({
    router,
    dsn: 'https://dc76d3e238fe1e7f3f1efa0ef67c581f@sentry.fintopia.tech/59'
});

setSentryUser();
setSentryCache();

if (module.hot) {
    module.hot.accept();
}
