/*
 * @Author: zhaoyang
 * @Date: 2024-09-09 15:36:51
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-09-12 16:33:28
 */
import { axios } from '@yqg/resource';
import { STAGE } from '@shared/common/constant/stage';
import { isServer } from '../constant/config';
import { isAndroid, isIOS } from './webview/ua';
const prodIndo = /prod-indo/i.test(STAGE);
const uploadLogMap = {
    'test': 'https://event-tracking-api-test.yangqianguan.com/logMetrics',
    'prod': 'https://event-tracking-api.yangqianguan.com/logMetrics',
    'prod-indo': 'https://event-tracking-api.easycash.id/logMetrics'
};
const uploadLogKey = prodIndo ? 'prod-indo' : 'test';
export const api = {
    uploadLog: uploadLogMap[uploadLogKey]
};
export default function initRealTimeEventLog(appName, appId, country) {
    if (isServer())
        return async () => { };
    return async function (eventName, parameter, logSetting) {
        try {
            const { level = 'INFO' } = logSetting ?? {};
            const ua = window.navigator.userAgent;
            const url = window.location.href;
            let osType = 'WEB';
            if (isAndroid(ua)) {
                osType = 'ANDROID';
            }
            else if (isIOS(ua)) {
                osType = 'IOS';
            }
            await axios.post(api.uploadLog, {
                level,
                logs: [{
                        appId,
                        metricsType: eventName,
                        measurement: `app_metrics_for_event_log_${appName}`,
                        time: +new Date(),
                        osType,
                        uuid: parameter?.uuid,
                        message: parameter?.message,
                        parameter: {
                            url,
                            userAgent: ua,
                            ...parameter
                        }
                    }]
            }, {
                headers: {
                    'YQG-PLATFORM-SDK-TYPE': appId,
                    'Country': country
                },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hideLoading: true,
                hideToast: true
            });
        }
        catch (error) {
            // ignore
        }
    };
}
