/*
 * @Author: zhaoyang
 * @Date: 2024-04-16 14:51:33
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-08-28 14:37:16
 */

const routes = [
    // Profile Start
    {
        path: 'my-profile',
        name: 'MyProfile',
        component: () => import(
            /* webpackChunkName: "ssr-stable.my-profile" */
            'easycash/app/webview/biz-new/my-profile')
    },
    {
        path: 'add-email',
        name: 'AddEmail',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import(
            /* webpackChunkName: "ssr-stable.add-email" */
            'easycash/app/webview/biz-new/my-profile/add-email')
    },
    {
        path: 'verify-email',
        name: 'VerifyEmail',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import(
            /* webpackChunkName: "ssr-stable.verify-email" */
            'easycash/app/webview/biz-new/my-profile/verify-email')
    },
    {
        path: 'email-fast-link',
        name: 'EmailFastLink',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/my-profile/email-fast-link')
    },
    {
        path: 'email-status',
        name: 'EmailStatus',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import(
            /* webpackChunkName: "ssr-stable.email-status" */
            'easycash/app/webview/biz-new/my-profile/email-status')
    },
    // Profile End
    {
        path: 'loanrestruture',
        name: 'loanRestruture',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/loan-restructure')
    },
    {
        path: 'loanrestruture/sign-now',
        name: 'loanRestrutureSignNow',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/loan-restructure/sign-now')
    },
    {
        path: 'loanrestruture/agreement',
        name: 'loanRestrutureAgreement',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/loan-restructure/agreement')
    },
    {
        path: 'loanrestruture/contract',
        name: 'loanRestrutureContract',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/loan-restructure/contract')
    },
    {
        path: 'loanrestruture/contractsuccess',
        name: 'loanRestrutureContractSuccess',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/loan-restructure/contract-success')
    },
    {
        path: 'viprights',
        name: 'vipRights',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/vip-rights')
    },
    {
        path: 'no-login-fill',
        name: 'NoLoginFill',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/no-login-bill')
    },
    {
        path: 'no-login-bills',
        name: 'NoLoginBills',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('easycash/app/webview/biz-new/no-login-bill/bills')
    }
];

export default routes;
